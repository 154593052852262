<template>
    <div id="app">
      <router-view v-if="routerAlive"></router-view>
    </div>
</template>
<script>
import {getTokenDemo} from "./api/index";
export default {
    name: 'App',
    // 无感刷新
    provide() {
        return {
            reload: this.reload,
        }
    },
    data() {
        // 无感刷新参数
        return { routerAlive: true }
    },
    created(){
        this.getToken()
        // var sUserAgent = navigator.userAgent.toLowerCase();
        // var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
        // var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
        // var bIsMidp = sUserAgent.match(/midp/i) == "midp";
        // var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
        // var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
        // var bIsAndroid = sUserAgent.match(/android/i) == "android";
        // var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
        // var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
        // if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
        //     this.getToken()
        // } else {
        //     this.getToken()
        // }
    },
    mounted () {
        // 关闭浏览器时 清除本地local值
        window.addEventListener('beforeunload', e => {
            window.localStorage.removeItem('user_lang')
        });
    },
    methods: {
        //重新加载的方法
        reload() {
            this.routerAlive = false
            this.$nextTick(function () {
                this.routerAlive = true
            })
        },
        // 测试
        getToken(){
            let para ={
                url: String(location.href.split('#')[0])

            }

            getTokenDemo(para).then(res => {
                // alert(JSON.stringify(res)+navigator.userAgent)
                if (res.code === 200) {
                    wx.config({
                        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                        // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: 'ww0ade117fa232c373', // 必填，企业微信的corpID，必须是本企业的corpID，不允许跨企业使用
                        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                        signature: res.data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                        jsApiList: [
                            "hideOptionMenu",
                            'hideAllNonBaseMenuItem',
                        ] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                    });
                    let that = this
                    //配置成功以后config:ok
                    wx.ready(function (res) {
                        //隐藏右上角菜单接口
                        wx.hideAllNonBaseMenuItem({
                            fail:function (err){
                                that.$message.error(JSON.stringify(err)+'隐藏非基础按钮错误提示')
                            }
                        });
                        wx.hideOptionMenu({
                            fail:function (err){
                                that.$message.error(JSON.stringify(err)+'隐藏右上角菜单错误提示')
                            }
                        });

                    })

                    wx.error(function(err){
                        that.$message.error(JSON.stringify(err)+'信息验证失败报错')
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                    });
                } else {
                    this.readLoad = false
                    if (!isInterceptorCode(res.code)) {
                        this.$message.error(res.msg)
                    }
                }
            }).catch((err) => {
                this.readLoad = false
                // this.$message.error('服务器连接失败200!')
                this.$message.error(err,'获取企微验证信息失败')
            })
        },
    },

}
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
#app {
  margin: 0;
  padding: 0;
}
.el-pagination {
  margin-top: 15px;
}
.el-main{
  background:#F5F5FC ;
  padding: 0;
}
.main-body{
  padding: 40px 40px 97px 0;
  background: #F5F7FB;
}
.el-breadcrumb {
  height: 50px;
  padding-left: 20px;
  margin-bottom: 20px;
  background: #fff;
  line-height: 50px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 25%;
}
.spousePhone {
  display: flex;
}
.el-drawer__body {
  overflow-y: auto;
}
.el-timeline {
  padding: 0 30px;
}
.el-card__body {
  padding: 0 20px;
}
/*动态标签*/
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-tag {
  cursor: pointer;
}
.unifiedWidth {
  width: 245px !important;
}
.el-card__header {
  background: #F5F7FA;
}
.el-card__body {
  padding: 0 30px;
}
.card-table {
  margin-top: 15px;
}

.el-card {
  border: 1px solid rgba(192, 196, 204, .8);
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.remarks-table {
  display: flex;
}

.el-cascader-menu__wrap {
  height: 204px !important;
}

/*.no-states{*/
/*  text-align: center;*/
/*  width: 342px;*/
/*  height: 237px;*/
/*  background: #fff url('./assets/noStates.jpg') no-repeat center/85%;*/
/*  margin: 0 auto;*/
/*  position: relative;*/
/*}*/

.no-states p{
  color: #72767b;
  position: absolute;
  top: 240px;
  left: 45%;
  text-align: center;
}

.el-radio-button:first-child .el-radio-button__inner {
  border: none;
}

.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  box-shadow: none;
}

.null-data {
  color: #909399;
}

.el-cascader-menu__list {
  padding: 6px 10px 6px 0;
}

.detail-click:hover {
  cursor: pointer;
}

/*优先标志*/
.priority-span {
  display: inline-block;
  height: 15px;
  margin-left: 5px;
  padding: 3px 5px;
  border-radius: 4px;
  background: #EF3054;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
}

.popColor{
  opacity: 0.5;
}
</style>
